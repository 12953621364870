import './navi.scss';
import { NavLink } from 'react-router-dom';
import profile from '../../images/profile.svg';
import home from '../../images/home.svg';
import stats from '../../images/stats.svg';

export default function Navigation () {
    return (
        <nav className="nav">
            <NavLink to="/" exact activeClassName="active" className="nav-item">
                <img className="nav-icon" src={home} alt="Home"/>
            </NavLink>
            <NavLink to="/profile" activeClassName="active" className="nav-item">
                <img className="nav-icon" src={profile} alt="Profile"/>
            </NavLink>
            <NavLink to="/stats" activeClassName="active" className="nav-item">
                <img className="nav-icon" src={stats} alt="Stats"/>
            </NavLink>
        </nav>
    )
}