import './task.scss';
import PopOver from '../PopOver';
import { useState } from 'react';
import checkMark from '../../images/checked.svg'
import { toggleDone } from '../../logic/tasks';

export default function Task({ name, description, topicId, id, checked }) {
    
    const [clicked, setClicked] = useState(false);
    const [done, setDone] = useState(checked ?? false);

    return (
        <>
            <div className="task">
                <div className={"checkbox" + (done ? " checked" : "")} onClick={() => 
                    {
                        setDone((isDone) => !isDone);
                        toggleDone(topicId, id);
                    }
                    }>

                    {done && <img src={checkMark} alt="checked"/>}
                </div>
                <h4 className="title" onClick={() => setClicked(true)}>{name}</h4>
            </div>
            {clicked && <PopOver heading={name} description={description} setOpen={setClicked} />}
        </>
    )
}