import './info.scss';
import { useHistory } from 'react-router-dom';
import Illu from '../../images/big-illu.svg';
import arrow from '../../images/arrow-black.svg';
import { Helmet } from 'react-helmet';

export default function Info() {

    const history = useHistory();

    return (
        <div className="introduction info-page">
            <Helmet>
                <title>About | ecocoon</title>
            </Helmet>
            <button onClick={() => history.goBack()} className="back">
                <img src={arrow} alt="Zurück" className="arrow" />Zurück</button>
            <div className="illustration">
                <img className="illu" src={Illu} alt="Illustration" />
            </div>
            <div className="pop-over">
                <div className="content-container">
                    <h1 className="heading large">
                        <span className="green">About</span>
                    </h1>
                    <p>
                        Designed and developed by <strong>Tanja Thomaser.</strong> <br /><br />
                        Powered by <strong>Systent GmbH.</strong> <br /><br />
                        Special thanks to <strong>Daniel Planötscher</strong> for the help.
                    </p>
                </div>
            </div>
        </div>
    )
}