import Header from '../../components/Header';
import Topic from '../../components/Topic';
import { getAllTopics } from '../../logic/topics';
import { Helmet } from 'react-helmet';

export default function Home() {
    const topics = getAllTopics();
    return (
        <>
            <Helmet>
                <title>Themenbereiche | ecocoon</title>
            </Helmet>
            <Header
                headingSmall="Was bedeutet"
                headingLarge="Nachhaltigkeit?"
                text="Kurzgesagt bedeutet Nachhaltigkeit mit der Welt und ihren natürlichen Ressourcen so schonend umzugehen,
            dass sie für zukünftige Generationen noch genauso zur Verfügung stehen."
                backButton={false}
            />

            <div className="overview">
                <div className="content-container">
                    <h2 className="heading medium">
                        Thema auswählen
                </h2>
                    <div className="overview-grid">
                        {topics.map((topic) => (
                            <Topic
                                key={topic.id}
                                id={topic.id}
                                icon={topic.icon}
                                title={topic.name}
                                slug={topic.slug}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </>

    )
}

