import './introduction.scss';
import Illu from '../../images/big-illu.svg';
import { setJoined } from '../../logic/intro';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Introduction() {
    const history = useHistory();
    return (
        <div className="introduction">
            <Helmet>
                <title>Willkommen bei ecocoon</title>
            </Helmet>
            <div className="illustration">
                <img className="illu" src={Illu} alt="Illustration" />
            </div>
            <div className="pop-over">
                <div className="content-container">
                    <h1 className="heading large">
                        <span className="green">Hey!</span>
                    </h1>
                    <p>
                        Hier bei <strong>ecocoon</strong> kannst du dich durch interaktive Aufgaben mit dem Thema <strong>Nachhaltigkeit</strong> in verschiedenen Bereichen auseinandersetzen
                            und etwas Neues dazulernen. Bist du bereit?
                        </p>
                    <button onClick={() => {
                        setJoined();
                        history.push("/");
                        window.location.reload();
                    }} className="button">Los geht's!</button>
                </div>
            </div>
        </div>
    )
}