export function getAvatar(id) {
    return avatars[id];
}

export function getAllAvatars() {
    return avatars;
}

export function getCurrentAvatarId() {
    return localStorage.getItem("avatar-id") ?? 0;
}

export function setCurrentAvatarId(id) {
    if (id < avatars.length && id >= 0) {
        localStorage.setItem("avatar-id", id);
    }
}

export function getCurrentAvatar() {
    return getAvatar(getCurrentAvatarId());
}

const avatars = [
    {
        id: 0,
        icon: "avatar_one.svg",
        color: "#4196A5"
    },
    {
        id: 1,
        icon: "avatar_two.svg",
        color: "#1B6453"
    },
    {
        id: 2,
        icon: "avatar_three.svg",
        color: "#649C66"
    },
    {
        id: 3,
        icon: "avatar_four.svg",
        color: "#649C66"
    },
    {
        id: 4,
        icon: "avatar_five.svg",
        color: "#1B6453"
    },
    {
        id: 5,
        icon: "avatar_six.svg",
        color: "#649C66"
    }
]