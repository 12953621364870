import Header from '../../components/Header';
import Task from '../../components/Task';
import { useParams, Link } from 'react-router-dom';
import { getTopicBySlug } from '../../logic/topics';
import { getOpenTasks, getDoneTasks } from '../../logic/tasks';
import chevron from '../../images/chevron.svg';
import { useState } from 'react';
import './theme.scss';
import { Helmet } from 'react-helmet';

export default function Theme() {
    const { topicName } = useParams();
    const topic = getTopicBySlug(topicName);
    const [showDone, setShowDone] = useState(false);

    if (!topic) {
        return (
            <div className="content-container">
                <h1 style={{ paddingTop: "50px" }}>Sorry, {topicName} konnte nicht gefunden werden. :( </h1>
                <Link className="button" to="/" >Zurück zur Übersicht</Link>
            </div>
        );
    }

    const tasks = getOpenTasks(topic);
    const doneTasks = getDoneTasks(topic);

    return (
        <>
            <Helmet>
                <meta name="description" content={topic.description} />
                <title>{topic.name} | ecocoon</title>
            </Helmet>
            <Header
                headingSmall={topic.subtitle}
                headingLarge={topic.name}
                text={topic.description}
                backButton={true}
            />

            <div className="task-section">
                <div className="content-container">
                    <h2 className="heading medium">
                        Deine Aufgaben
                    </h2>
                    <div className="task-container">
                        {tasks.length === 0 && <div className="completed">Super, du hast alle Aufgaben erledigt!</div>}
                        {tasks.map((task) => (
                            <Task
                                topicId={topic.id}
                                id={task.id}
                                key={task.id}
                                name={task.name}
                                description={task.description}
                            />
                        ))}
                    </div>
                    <div className={"accordion" + (showDone ? " open" : "")}>
                        <div className="heading-container" onClick={() => setShowDone(state => !state)}>
                            <img src={chevron} alt="Chevron" />
                            <h3 className="heading small">Erledigt</h3>
                        </div>

                        <div className="task-container">
                            {doneTasks.map((task) => (
                                <Task
                                    topicId={topic.id}
                                    id={task.id}
                                    key={task.id}
                                    name={task.name}
                                    description={task.description}
                                    checked={true}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

