import './topics.scss';
import { Link } from 'react-router-dom';
import { topicIsDone } from '../../logic/topics';

export default function Topic ({icon, title, slug, id}) {
    return (       
            <Link to={"/topic/" + slug} className={"topic" + (topicIsDone(id) ? " done" : "")}>
                <div className="inner">
                    <img className="icon" src={'images/topics/' + icon} alt={title}/>
                    <div className="title">{title}</div>
                </div>
            </Link>
    )
}