import {toggleDone as toggleDoneTopics} from './topics';

export function toggleDone(topicId, taskId) {
    let done = getDone(topicId);

    let open = getOpen(topicId);

    if (open.indexOf(taskId) >= 0) {

        done.push({ id: taskId, ts: Date.now() });
        removeOpen(taskId, topicId);
        setDone(done, topicId);

    } else {

        removeDone(taskId, topicId);
        open.push(taskId);
        setOpen(open, topicId);

    }

    toggleDoneTopics(topicId);
}

export function getDone(topicId) {
    return JSON.parse(localStorage.getItem("done-" + topicId)) ?? [];
}

export function getOpen(topicId) {
    return JSON.parse(localStorage.getItem("open-" + topicId)) ?? [];
}

function removeOpen(taskId, topicId) {
    let open = getOpen(topicId);
    open = open.filter(task => task !== taskId);
    setOpen(open, topicId);
}

function setOpen(open, topicId) {
    localStorage.setItem("open-" + topicId, JSON.stringify(open));
}

function setDone(done, topicId) {
    localStorage.setItem("done-" + topicId, JSON.stringify(done));
}

function removeDone(id, topicId) {
    let done = getDone(topicId);
    done = done.filter(task => task.id !== id);
    setDone(done, topicId);
}

function isNotUnknown(done, open, id) {
    return done.find(task => task.id === id) || open.find(task => task === id);
}

function getOpenTaskId(done, open, max) {
    let id;
    do {
        id = Math.floor(Math.random() * max + 1);
    } while (isNotUnknown(done, open, id));

    return id;
}

export function getOpenTasks(topic) {
    const tasks = topic.tasks;
    const done = getDone(topic.id);
    let open = getOpen(topic.id);

    while (open.length < topic.maxTasks && (done.length + open.length) < tasks.length) {
        open.push(getOpenTaskId(done, open, tasks.length));
    }
    setOpen(open, topic.id);

    return tasks.filter(task => open.find(o => o === task.id));
}

export function getDoneTasks(topic) {
    const tasks = topic.tasks;
    const done = getDone(topic.id);

    return tasks.filter(task => done.find(d => d.id === task.id));
}


export function getDoneTasksPercent(topic) {
    return getDone(topic.id).length / (topic.tasks.length ?? 1);
}