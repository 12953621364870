import Header from '../../components/Header';
import Avatar from '../../components/Avatar';
import './stats.scss';
import { getCurrentAvatar } from '../../logic/avatars';
import Progress from '../../components/Progress';
import { getAllTopics } from '../../logic/topics';
import { getDoneTasksPercent } from '../../logic/tasks';
import { Helmet } from 'react-helmet';

export default function Stats() {
    const currentAvatar = getCurrentAvatar();
    const topics = getAllTopics();
    return (
        <div>
            <Helmet>
                <title>Fortschritte | ecocoon</title>
            </Helmet>
            <Header
                headingLarge="Deine Fortschritte"
                text="Hier siehst du deine Aktivität und deine Fortschritte
                bei den einzelnen Themenbereichen. Mach weiter so!"
                backButton={true}
            />
            <div className="stats-section">
                <div className="content-container">
                    <div className="avatar-large">
                        <Avatar
                            icon={currentAvatar.icon}
                            color={currentAvatar.color}
                            large={true}
                        />
                    </div>
                    <h2 className="heading medium">
                        Deine Fortschritte
                    </h2>
                    <div className="stats-container">
                        {topics.map((topic) => (
                            <div key={topic.id} className="progress-container">
                                <Progress
                                    percent={getDoneTasksPercent(topic)}
                                />
                                <h3>{topic.name}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}