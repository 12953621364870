import Header from '../../components/Header';
import Avatar from '../../components/Avatar';
import './profile.scss';
import { getAllAvatars, setCurrentAvatarId, getCurrentAvatar } from '../../logic/avatars';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Profile() {
    const avatars = getAllAvatars();
    const [currentAvatar, setCurrentAvatar] = useState(getCurrentAvatar());
    return (
        <div>
            <Helmet>
                <title>Profil | ecocoon</title>
            </Helmet>
            <Header
                headingLarge="Dein Profil"
                text="Hier kannst du ganz einfach dein Profilbild ändern,
                indem du auf einen der unteren Avatars klickst!"
                backButton={true}
            />

            <div className="profile-section">
                <div className="content-container">
                    <div className="avatar-large">
                        <Avatar
                            icon={currentAvatar.icon}
                            color={currentAvatar.color}
                            large={true}
                        />
                    </div>
                    <h2 className="heading medium">
                        Profilbild ändern
                    </h2>
                    <div className="profile-container">
                        {avatars.map((avatar) => (
                            <Avatar
                                key={avatar.id}
                                icon={avatar.icon}
                                color={avatar.color}
                                onClick={() => {
                                    setCurrentAvatarId(avatar.id);
                                    setCurrentAvatar(avatar);
                                }}
                                style={currentAvatar.id === avatar.id ? { opacity: 0.5 } : {}}
                            />))}
                    </div>
                </div>
            </div>

        </div>
    )
}