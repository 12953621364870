import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Stats from './pages/Stats';
import Navigation from './components/Navigation';
import Theme from './pages/Theme';
import Introduction from './pages/Introduction';
import Info from './pages/Info';
import { hasJoined } from './logic/intro';
import './index.scss';
import ScrollTop from './components/helpers/ScrollTop';

function App() {
  return (
    <Router>
      {hasJoined() && <Navigation />}
      <ScrollTop />
      <div className="app-wrapper">
        <Switch>
          {!hasJoined() && <Route path="/" component={Introduction} />}
          <Route path="/profile" component={Profile} />
          <Route path="/stats" component={Stats} />
          <Route path="/topic/:topicName" component={Theme} />
          <Route path="/info" component={Info} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
