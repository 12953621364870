import './header.scss';
import { useHistory, Link } from 'react-router-dom';
import arrow from '../../images/arrow.svg';
import info from '../../images/info.svg';

export default function Header ({headingLarge, headingSmall, text, backButton}) {

    const history = useHistory ();

    let backButtonTag = <div></div>;
    if (backButton) {
        backButtonTag = (<button onClick= {() => history.goBack()} className="back">
        <img src={arrow} alt="Zurück" className="arrow"/>Zurück</button>);
    }
    return (
        <div className="header">
            <div className="content-container">
                <div className="navigation-container">
                {backButtonTag}
                <Link to="/info">
                    <img src={info} alt="Info" className="info"/>
                </Link>
                </div>
                <h1 className="heading large">
                    {headingSmall&& <span className="small">{headingSmall}</span>}
                    {headingLarge}
                </h1>
                <p>
                    {text}
                </p>
            </div>
        </div>
    )
}