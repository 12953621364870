import { topics } from '../data/topics.json';
import { getDone as getDoneTasks } from './tasks';

export function topicIsDone(id) {
    return getDone().find(topic => topic === id);
}

export function getAllTopics() {
    return getOpenTopics(topics).concat(getDoneTopics(topics));
}

export function getTopicBySlug(slug) {
    return topics.find((topic) => topic.slug === slug);
}

export function getTopicById(id) {
    return topics.find((topic) => topic.id === id);
}


export function toggleDone(topicId) {
    const topic = getTopicById(topicId);
    const doneTasks = getDoneTasks(topic.id);

    let doneTopics = getDone();

    let open = getOpen();

    if (doneTasks.length >= topic.tasks.length) {

        doneTopics.push(topic.id);
        removeOpen(topic.id);
        setDone(doneTopics);

    } else {

        removeDone(topic.id);
        open.push(topic.id);
        setOpen(open);

    }
}

function getDone() {
    return JSON.parse(localStorage.getItem("done-topics")) ?? [];
}

function getOpen() {
    return JSON.parse(localStorage.getItem("open-topics")) ?? [];
}

function removeOpen(topicId) {
    let open = getOpen();
    open = open.filter(topic => topic !== topicId);
    setOpen(open, topicId);
}

function setOpen(open) {
    localStorage.setItem("open-topics", JSON.stringify(open));
}

function setDone(done) {
    localStorage.setItem("done-topics", JSON.stringify(done));
}

function removeDone(topicId) {
    let done = getDone();
    done = done.filter(topic => topic !== topicId);
    setDone(done);
}

function isNotUnknown(done, open, id) {
    return done.find(topic => topic === id) || open.find(topic => topic === id);
}

function getOpenTopicId(done, open, max) {
    let id;
    do {
        id = Math.floor(Math.random() * max + 1);
    } while (isNotUnknown(done, open, id));

    return id;
}

function getOpenTopics(topics) {
    const done = getDone();
    let open = getOpen();

    while (open.length < 4 && (done.length + open.length) < topics.length) {
        open.push(getOpenTopicId(done, open, topics.length));
    }
    setOpen(open);

    return topics.filter(topic => open.find(o => o === topic.id));
}

function getDoneTopics(topics) {
    const done = getDone();

    return topics.filter(topic => done.find(o => o === topic.id));
}