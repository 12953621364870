import './pop-over.scss';
import { useState } from 'react';

export default function PopOver({ heading, description, setOpen }) {

    const [closed, setClosed] = useState(false);
    const [startPosition, setStartPosition] = useState();

    const handleClose = () => {
        setClosed(true);
        setTimeout(() => setOpen(false), 300);
    }

    const handleStartSwipe = (event) => {
        let elementTop = event.touches[0].target.offsetTop;
        let touchTop = event.touches[0].screenY;
        if(Math.abs(elementTop - touchTop) < 300) {
            document.querySelector('body').style.overflow = 'hidden';
            setStartPosition(event.touches[0].screenY);
        }
    }

    const handleEndSwipe = (event) => {
        if(startPosition < event.changedTouches[0].screenY - 50) {
            handleClose();
            document.querySelector('body').style.overflow = 'auto';
        }
        setStartPosition(undefined);
    }
    

    return (
        <div className={"pop-over-container" + (closed ? " closed" : "")}>

            <div className="pop-over-background" onClick={handleClose}></div>
            <div className="pop-over pull"  
            onTouchStart={handleStartSwipe} 
            onTouchEnd={handleEndSwipe}>
                <div className="content-container">
                    <h1 className="heading medium">
                        {heading}
                    </h1>
                    <p>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}